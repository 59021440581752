import { Link } from "gatsby"
import React from "react"

import Dropdown from "../../../../../design-system/dropdown"
import Paragraph from "../../../../../design-system/paragraph"
import { DEFAULT_BUCKET } from "../../../../../experimentation/constants"
import { BucketValidCombination } from "../../../../../experimentation/types"
import { Pathname } from "../../../../../experimentation/types/base-paths"
import {
  getFullExperimentPathFromCombination,
  getPossibleCombinationsByPath,
} from "../../../../../experimentation/utils"
import { navigate } from "../../../../../utils/navigate"
import * as styles from "./index.module.scss"

export type Props<T extends Pathname> = {
  basePath: T
  combination: BucketValidCombination<T>
  isRealBuckets: boolean
  experimentId: keyof BucketValidCombination<T>
  windowHash: string
}

function ExperimentBucketSelectorDropdown<T extends Pathname>({
  basePath,
  combination,
  isRealBuckets,
  experimentId,
  windowHash,
}: Props<T>) {
  const possibleCombinations = getPossibleCombinationsByPath(basePath)
  const variants = possibleCombinations.reduce<Set<string>>(
    (variantSet, possibleCombination) => {
      const bucket = possibleCombination[experimentId]
      if (bucket) {
        variantSet.add(bucket)
      }
      return variantSet
    },
    new Set(),
  )

  const realBucket: string = combination[experimentId] ?? "Undefined"
  const isInvalidBucket = !variants.has(realBucket)
  const shouldShowFallbackBucket = isInvalidBucket && !isRealBuckets
  const variantsForOptions = [
    ...variants,
    ...(isInvalidBucket && isRealBuckets ? [realBucket] : []),
  ]
  const options = variantsForOptions.map(variant => ({
    label: variant,
    value: variant,
  }))

  function getNewLink(
    combinationReplacement: Partial<BucketValidCombination<T>>,
  ) {
    const newCombination = {
      ...combination,
      ...combinationReplacement,
    }
    const newLink = getFullExperimentPathFromCombination(
      basePath,
      newCombination,
    )
    return newLink
  }

  return (
    <div key={experimentId}>
      <strong>{experimentId}</strong>
      <Dropdown
        small
        className={styles.dropdown}
        value={shouldShowFallbackBucket ? DEFAULT_BUCKET : realBucket}
        options={options}
        data-testid={`experiment-bucket-selector-${experimentId}`}
        renderOption={({ value, label }) => {
          const newLink = getNewLink({ [experimentId]: value } as Partial<
            BucketValidCombination<T>
          >)
          return (
            // eslint-disable-next-line react/forbid-elements
            <Link
              to={newLink}
              onMouseDown={() => navigate(newLink + windowHash)} // Needed because Dropdown internally prevents the default Link click
            >
              <Paragraph variant="3" style={{ padding: "4px 8px" }}>
                {label}
              </Paragraph>
            </Link>
          )
        }}
      />
    </div>
  )
}

export default ExperimentBucketSelectorDropdown
